import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  errorMessage: string;
  loginSuccess: boolean;
  loginError: boolean;

  constructor(
    public firebaseAuth: AngularFireAuth,
    private router: Router
  ) {
    this.loginSuccess = false;
    this.loginError = false;
  }

  doRegister(email, password) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(email, password).then(result => {
        this.loginSuccess = true;
        localStorage.setItem('loggedIn', 'true');
        resolve(result);
      }).catch(error => {
        reject(error);
      });
    });
  }

  resetPassword(email) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().sendPasswordResetEmail(email)
      .then(res => {
        resolve(res);
      }, err => reject(err));
    });
  }

  doLogin(email, password) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password).then(result => {
        this.loginSuccess = true;
        localStorage.setItem('loggedIn', 'true');
        resolve(result);
      }).catch(error => {
        reject(error);
      });
    });
  }

  getCurrentUser() {
    const user =  firebase.auth().currentUser;
    return user;
  }

  logout(): void {
    this.loginSuccess = false;
    this.firebaseAuth.signOut().then(_ => this.router.navigate(['/login']));
  }
}
