import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './core/authentication/authentication.guard';


const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('../app/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'eventos',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../app/events/events.module').then((m) => m.EventsModule)
  },
  // {
  //   path: 'evento/:id/tickets',
  //   canActivate: [AuthenticationGuard],
  //   loadChildren: () => import('../app/sales2/sales.module').then((m) => m.SalesModule)
  // },
  {
    path: 'evento/:id/newsale',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../app/sale/sales.module').then((m) => m.SalesModule)
  },
  {
    path: 'evento/:id/report',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../app/reports/sales.module').then((m) => m.SalesModule)
  },
  {
    path: 'wolipay',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('../app/qr-simple/sales.module').then((m) => m.SalesModule)
  },
  {
    path: '',
    redirectTo: '/eventos',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
